<template>
  <section class="section">
    <h1 class="title">
      Closed Auctions
    </h1>
    <div class="column">
      <Table
        :input-data="tableData"
        :columns="['auction_id', 'product', 'winner']"
        sortable
        :narrowed="false"
        paginated
        hoverable
        sort-multiple
      />
    </div>
    <div class="has-text-left">
      <router-link
        class="is-italic"
        to="/dashboard"
      >
        <b-icon
          icon="chevron-left"
          @click.native="$router.push('/dashboard')"
        />
        dashboard
      </router-link>
    </div>
  </section>
</template>

<script>
import Table from '../components/shared/charts/table.vue'

export default {
  name: 'ClosedAuctions',
  components: {
    Table
  },
  data: () => {
    return {
      tableData: [
        {
          id: 1,
          auction_id: 'Auction123',
          product: 'homeInsurance',
          winner: 'NN'
        },
        {
          id: 2,
          auction_id: 'Auction321',
          product: 'homeInsurance',
          winner: 'NN'
        },
        {
          id: 3,
          auction_id: 'Auction121',
          product: 'travelInsurance',
          winner: 'Aegon'
        },
        {
          id: 4,
          auction_id: 'Auction312',
          product: 'carInsurance',
          winner: 'OHRA'
        },
        {
          id: 5,
          auction_id: 'Auction612',
          product: 'petInsurance',
          winner: 'AON'
        },
        {
          id: 3,
          auction_id: 'Auction121',
          product: 'travelInsurance',
          winner: 'Aegon'
        },
        {
          id: 4,
          auction_id: 'Auction312',
          product: 'carInsurance',
          winner: 'OHRA'
        },
        {
          id: 5,
          auction_id: 'Auction612',
          product: 'petInsurance',
          winner: 'AON'
        }
      ]
    }
  },
  computed: {},
  mounted () {
    // this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style lang="scss" scoped>
</style>
