<template>
  <transition name="show">
    <div
      v-show="auctionId"
      class="box has-text-centered"
    >
      <h1 class="title">
        {{ auctionId }}
      </h1>
      <div class="columns">
        <div class="column">
          <h1 class="subtitle">
            Timer
          </h1>
          <Auctiontimer
            :time-left-prop="100"
            :timelimit="300"
          />
          <!-- <h1 class="is-size-6 is-italic">Opstalverzekering</h1> -->
        </div>
        <div class="column">
          <div class="subtitle">
            Offers
          </div>
          <Table
            sortable
            :input-data="tableData"
            :columns="[
              'offer',
              'insurer']"
          />
          <!-- <div class="column">
            <div class="box">
              <p style="font-size: 0.7em">
                10EUR - NN
              </p>
            </div>
            <div class="box">
              <p style="font-size: 0.7em">
                13EUR - AEGON
              </p>
            </div>
            <div class="box">
              <p style="font-size: 0.7em">
                14EUR - OHRA
              </p>
            </div>
          </div> -->
        </div>
        <div class="column">
          <h1 class="subtitle">
            Participation
          </h1>
          <div class="column has-text-centered">
            <b-field
              class="has-text-left"
              label="My offer"
            >
              <b-numberinput
                v-model="offer"
                type="is-link"
              />
            </b-field>

            <b-field
              class="has-text-left"
              label="Confirm offer"
            >
              <b-numberinput
                v-model="confirmedOffer"
                type="is-link"
              />
            </b-field>
            <b-button
              :disabled="offer != confirmedOffer"
              icon-left="check"
              expanded
              type="is-primary"
            >
              Place offer
            </b-button>
          </div>
        </div>
      </div>
      <b-tabs expanded>
        <b-tab-item
          label="Client data"
          icon="home"
        >
          <div class="has-text-left">
            Client data placeholder
          </div>
        </b-tab-item>
        <b-tab-item
          label="Auction data"
          icon="gavel"
        >
          <div class="has-text-left">
            Auction data placeholder
          </div>
        </b-tab-item>
        <b-tab-item
          label="Pricing analytics"
          icon="chart-line"
        >
          <div class="has-text-left">
            Pricing placeholder
          </div>
        </b-tab-item>

        <!-- <b-tab-item label="Videos" icon="video"></b-tab-item> -->
      </b-tabs>
      <div class="has-text-right">
        <b-button
          icon-left="times"
          type="is-warning "
          outlined
          inverted
        >
          Reject
        </b-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Auctiontimer from "./shared/auctiontimer.vue";
import Table from "./shared/charts/table";

export default {
  name: "Auction",
  components: {
    Auctiontimer,
    Table,
  },
  props: {
    auctionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      offer: 14,
      confirmedOffer: 12,
      tableData: [
        { offerId: "xyz", offer: 13, insurer: "NN" },
        { offerId: "xdz", offer: 12, insurer: "AEGON" },
        { offerId: "xaz", offer: 14, insurer: "OHRA" },
        { offerId: "xyz", offer: 19, insurer: "AON" },
        { offerId: "xdz", offer: 9, insurer: "Centraal beheer" },
      ],
    };
  },
};
</script>

<style lang="scss">
.tabs a {
  color: white;
}

// .tabs a:hover {
//     color: $light
// }
</style>
