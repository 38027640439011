<template>
  <section class="section">
    <div class="column is-half is-offset-one-quarter">
      <h1 class="title">
        Sign in
      </h1>
      <div class="box">
        <form @submit.prevent="postLogin(loginData)">
          <b-field label="API key">
            <b-input v-model="loginData.apikey" />
          </b-field>
          <b-field label="API secret">
            <b-input v-model="loginData.apisecret" />
          </b-field>
          <b-button
            native-type="submit"
            type="is-link"
          >
            Sign in
          </b-button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loginData: {
        apikey: "",
        apisecret: "",
      },
    };
  },
  methods: {
    ...mapActions(["postLogin"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
