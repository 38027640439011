<template>
  <section class="section">
    <h1 class="title">
      Won Auctions
    </h1>
    <div class="column">
      <Table
        :input-data="tableData"
        :columns="['auction_id', 'client_id', 'product', 'status', 'policy', 'end_date']"
        sortable
        paginated
        hoverable
        sort-multiple
      />
    </div>
    <div class="has-text-left">
      <router-link
        class="is-italic"
        to="/dashboard"
      >
        <b-icon
          icon="chevron-left"
          @click.native="$router.push('/dashboard')"
        />
        dashboard
      </router-link>
    </div>
  </section>
</template>

<script>
import Table from "../components/shared/charts/table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      tableData: [
        {
          id: 1,
          auction_id: "Auction123",
          client_id: "Client1723",
          product: "homeInsurance",
          winner: "NN",
          status: "In acceptation proces",
                    policy: "link",

        },
        {
          id: 2,
          auction_id: "Auction321",
          client_id: "Client1724",

          product: "homeInsurance",
          winner: "NN",
                              policy: "link",

          status: "In acceptation proces",
        },
        {
          id: 3,
          auction_id: "Auction121",
          client_id: "Client1753",

          product: "travelInsurance",
          winner: "Aegon",
                              policy: "link",

          status: "In acceptation proces",
        },
        {
          id: 4,
          auction_id: "Auction312",
          client_id: "Client1883",

          product: "carInsurance",
          winner: "OHRA",
                              policy: "link",

          status: "Accepted",
           end_date: "18-04-2022",
        },
        {
          id: 5,
          auction_id: "Auction612",
          client_id: "Client8823",

          product: "petInsurance",
          winner: "AON",
                              policy: "link",

          status: "Accepted",
           end_date: "18-04-2022",
        },
        {
          id: 3,
          auction_id: "Auction121",
          client_id: "Client1711",

          product: "travelInsurance",
          winner: "Aegon",
                              policy: "link",

          status: "Accepted",
           end_date: "18-04-2022",
        },
        {
          id: 4,
          auction_id: "Auction312",
          client_id: "Client4523",

          product: "carInsurance",
          winner: "OHRA",
                              policy: "link",

          status: "Accepted",
           end_date: "11-05-2022",
        },
        {
          id: 5,
          auction_id: "Auction612",
          client_id: "Client9823",

          product: "petInsurance",
          winner: "AON",
                              policy: "link",

          status: "Accepted",
           end_date: "18-06-2022",
        },
      ],
    };
  },
};
</script>

<style>
</style>